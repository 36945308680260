
.react-ExcelUpload body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
  .react-ExcelUpload-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 450px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .react-ExcelUpload h3, h4 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .react-ExcelUpload input[type="file"] {
    display: block;
    margin: 0 auto 20px auto;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 2px dashed #007bff;
    cursor: pointer;
    background-color: #f9f9f9;
    width: 100%;
    max-width: 400px;
  }
  
  .react-ExcelUpload input[type="file"]:hover {
    background-color: #e2f0ff;
  }
  
  /* Button Styles */
  .react-ExcelUpload button {
    background-color: #C95191;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;     
    justify-content: center; 
    align-items: center; 
    position: absolute;     
    top: 42%;     
    left: 35%;    
    transform: translate(-50%, -50%);
  }
  
  .react-ExcelUpload button:hover {
    background-color: #60676e;
  }
  

  .react-ExcelUpload .alert {
    background-color: #ffc107;
    color: #856404;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin: 20px 0;
    display: none;
  }
  
  .react-ExcelUpload .success {
    background-color: #28a745;
    color: white;
  }
  
  .invoice-bulk body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
.invoice-bulk-upload-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 450px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.invoice-bulk h3, h4, h1, h2 {
    text-align: center;
    color: #333;
    font-size: 18px;
    margin-bottom: 15px;
  }
  
.invoice-bulk-h2 {
    color: #333;
    margin-bottom: 20px;
    font-size: 45px;
}

.invoice-bulk-file-name {
    padding:20px;
    width: 100%;
    max-width: 651px;
    border: 2px solid #4CAF50;
    border-radius: 6px;
    background-color: #f9f9f9;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 10px;
}


    .invoice-bulk-file-name:hover {
        background-color: #e6ffe6;
        border-color: #45a049;
    }


    .invoice-bulk-file-name::file-selector-button {
        border: none;
        padding: 10px 15px;
        margin-right: 10px;
        background-color: #4CAF50;
        color: white;
        font-size: 16px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s ease;
    }


        .invoice-bulk-file-name::file-selector-button:hover {
            background-color: #45a049;
        }


.invoice-bulk-btn-upload {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 12px 25px;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

    .invoice-bulk-btn-upload:hover {
        background-color: #0056b3;
    }

.invoice-bulk-message {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    color: #d9534f;
}

    .invoice-bulk-message-success {
        color: #28a745;
        text-align: center;
        margin-top: 15px;
        font-size: 14px;
    }

    .invoice-bulk-message.error {
        color: #d9534f;
    }

    .invoice-bulk.table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
      }
      
      /* .table th, .table td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: center;
      }
      
      .table th {
        background-color: #007bff;
        color: white;
      }
      
      .table-striped tbody tr:nth-child(odd) {
        background-color: #f2f2f2;
      }
       */
      .invoice-bulk-failed-rows-section {
        margin-top: 30px;
      }
      
      .invoice-bulk-message-success {
        background-color: #d4edda;
        color: #155724;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        margin: 20px 0;
      }
      
      .invoice-bulk-error-message {
        background-color: #f8d7da;
        color: #721c24;
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        margin: 20px 0;
      }
      
      invoice-bulk.input[type="checkbox"] {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        background-color: #FF4081;
        border: 1px solid #FF80AB;
        cursor: pointer;
      }
      
       
.form-stepper-container {
    padding: 20px;
    margin: 33px 129px;
    border-radius: 10px;
    background-color: #f7f7f7;
    max-width: 777px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
 
.invoice-upload-container, .failed-invoices-container {
    margin: 20px 0;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
}
 
.invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
}
 
.invoice-table th, .invoice-table td {
    padding: 12px 15px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}
.invoice-table th {
    background-color: #C95191; /* Pink header */
    color: white;
}
 
.invoice-table td {
    border-bottom: 1px solid #ddd;
}
 .invoice-table  tbody tr:hover {
    background-color: #f2f2f2;
    cursor: pointer;
} 
 
/* .invoice-table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
}
  */
.invoice-table-button {
    background-color: #727272;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
}
 
.invoice-table-button:hover {
    background-color: #C95191;
}

.retry-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.invoice-table-link {
    color: rgb(58, 56, 56);
    text-decoration: underline;
    font-weight: bold;
    cursor:pointer;
    font-size: small;
    padding-left: 550px;
}

.invoice-table-link :hover {
  text-decoration: underline;
  color:#0056b3;
}
.invoice-failed-row {
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
  }