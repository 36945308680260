.react-shared .completion-bar {
  width: 100%;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.react-shared .completed {
  background-color: green;
  height: 100%;
  transition: width 0.5s ease-in-out;
}

.react-shared .completion-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
  pointer-events: none; /* Prevent interaction with the text */
}