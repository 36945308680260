.width-100 {
  width: 100%;
}

.clinic-profile .container {
  display: flex;
  width: auto;
  flex-direction: column;
  border: 1px solid #ccc; /* Faint grey border */
}
.top-container {
  padding: 10px;
  text-align: left;
}
.bottom-container {
  display: flex;
  flex-direction: row;
}
.left-section {
  flex-basis: 30%;
}
.right-section {
  flex-basis: 50%;
  border-left: 1px solid #ccc; /* Vertical line to separate sections */
  padding-left: 10px; /* Add some padding to the right section */
}

/* flex */
.clinic-profile-flex-container {
  display: flex;
}

@media (max-width: 900px) {
  .flex-container {
    flex-direction: column;
  }
}

.clinic-profile-flex-container-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; /* Distribute items evenly */
  align-items: flex-start; /* Align items to the top of the container */
}
.clinic-profile-flex-cell {
  display: flex;
  flex: 50%;
}
.clinic-profile-flex-center {
  display: flex;
  align-items: center;
}
.clinic-profile-flex-item {
  flex: 1; /* Grow to occupy available space */
  margin: 5px; /* Add margin to create spacing between items */
}
