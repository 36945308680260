@charset "UTF-8";

/**
 *  Reset/Normalize
 * ------------------------------------------------------------------------- */
@import url('./normalize.css');

input,
select,
textarea {
    border-radius: 0; /* Removes border radius applied by Safari */
}
/**
 *  Variables
 * ------------------------------------------------------------------------- */
:root {
    --colour__primary: #8DB147;
    --colour__primary--dark: #8DB147;
    --colour__secondary: #DE7AAD;
    --colour__secondary--dark: #BD5196;
    --colour__font: #636362;
    --colour__font-alt: #FFF;
    --colour__grey: #E1E1E1;
    --colour__grey--dark: #9D9C9C;
    --colour__grey--light: #E6E6E6;
    --colour__white: #FFF;
    --colour__w3c__primary: #3A591D;
    --font: neo-sans, sans-serif;
    --transistion: 400ms ease-in-out all;
}

/**
 *  Useful documents section in form
 * ------------------------------------------------------------------------- */
.useful-documents-section{
    display: block;
    /* border: 1px solid red; */
    position: absolute;
    padding: 1em;
    width: 20em;
    left: 0;
    margin-bottom: 2em;
    margin-top: -1em;
    font-size: 1.3rem;
    line-height: 1.2;
}
@media only screen and (max-width: 1100px) {
    .useful-documents-section{
        width: 30em;
        /* border-color: blue; */
        position: relative;
        left: -1em;
    }
}
.useful-documents-section ul {
    color: var(--colour__secondary--dark);
}
.useful-documents-section .useful-documents-heading {
    color: var(--colour__w3c__primary);    
    margin-bottom: .3em;
}

/**
 *  Background
 * ------------------------------------------------------------------------- */


.typography {
    color: var(--colour__font);
    font-size: 1.3rem;
    line-height: 1.5;
}

.typography p {
    margin-bottom: 1em;
}


.typography strong {
    font-weight: 500;
}


.typography em {
    font-style: italic;
}

.typography hr {
    display: block;
    width: 100%; height: 0;
    margin: 36px 0 24px;
    border: 0;
    border-bottom: solid 1px var(--colour__font);
    outline: 0;
}

.typography h1,
.typography .h1 {
    margin-bottom: 1em;
    color: var(--colour__font);
    font-weight: 500;
    font-size: 1.8rem;
}


@media only screen and (min-width: 600px) {
    .typography h1,
    .typography .h1 {
        font-size: 2.4rem;
    }
}

.typography h2,
.typography .h2 {
    margin-bottom: 1em;
    color: var(--colour__font);
    font-weight: 500;
    font-size: 1.5rem;
}

@media only screen and (min-width: 600px) {
    .typography h2,
    .typography .h2 {
        font-size: 1.8rem;
    }
}


.typography h3,
.typography .h3 {
    margin-bottom: 1em;
    color: var(--colour__secondary);
    font-weight: 500;
    font-size: 1.5rem;
}

@media only screen and (min-width: 600px) {
    .typography h3,
    .typography .h3 {
        font-size: 1.6rem;
    }
}


.typography ul {
    margin: 0 0 1em; 
    /* padding: 0 0 0 1.5em; */
    list-style-type: disc;
}


.typography ol {
    margin: 0 0 1em; 
    padding: 0 0 0 1.5em;
    list-style-type: decimal;
}


.typography blockquote {
    margin: 0 0 1em;
    font-style: italic;
}

.typography blockquote p {
    margin: 0;
}

.typography cite {
    display: block;
    font-weight: bold;
    font-style: normal;
}

.typography sup,
.typography sub {
    position: relative;
    top: -0.4em;
    font-size: 80%;
    vertical-align: baseline;
}


.typography sub {
    top: 0.4em;
}


.typography u {
    text-decoration: none;
}


.typography a {
    color: inherit;
    font-size: 14px;
}

.typography a:link,
.typography a:visited {
    text-decoration: underline;
 }

.typography a:hover,
.typography a:active {
    text-decoration: none;
}

.typography .center {
    text-align: center;
}

.typography ul {
    list-style-type: none;
}

.typography ul li{
    margin-bottom: 5px;
}

.referrerListStyle li::before{
    position: relative;
    left: -0.5em;
    content: "+";
    color: var(--colour__primary--dark);
    line-height: 1.5;
}

.referrerListStyle li:hover::before{
    text-decoration: none;
}

.referrerListStyle li:hover{
    color: var(--colour__primary--dark);
    text-decoration: underline;
}

/* .typography ul li:before {
    position: relative;
    left: -0.5em;
    content: "+";
    color: #b2cf7c;
    line-height: 1.5;
} */

#anotherSubmitLink {
    font-size: 14px;
    cursor: pointer;
    color: var(--colour__secondary--dark);
}

#anotherSubmitLink {
    font-weight: 500;
    text-decoration: underline;
}

#anotherSubmitLink:hover {
    font-weight: 500;
    text-decoration: none;
}

.react-login check{
    width: 130px;
}

.react-login checkContainer{
    text-align: center;
    margin-bottom: 40px;
    margin-top: 20px;
}

/**
 *  Container
 * ------------------------------------------------------------------------- */
.Logincontainer {
    max-width: 1235px;
    width: 100%;
    margin: 0 auto;
    padding: 0 17.5px;
    display: flex;
    justify-content: center;

    display: flex;
    justify-content: center;

}

/**
 *  Header
 * ------------------------------------------------------------------------- */
.react-login header {
    margin-bottom: 10px;
    color: var(--colour__font-alt);
    line-height: 1.1;
}

@media only screen and (min-width: 750px) {
    .header {
        display: flex;
        justify-content: flex-end;
    }
}

.react-login header__date {
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 19px;
    display: block;
}

@media only screen and (min-width: 750px) {
    .header__date {
        width: 208px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.header__time {
    margin-left: 17px;
    display: inline-block;
}

@media only screen and (min-width: 750px) {
    .header__time {
        margin-left: auto;
    }
}

.header__user-account {
    margin-bottom: 19px;
    font-size: 12px;
}

@media only screen and (min-width: 750px) {
    .header__user-account {
        min-width: 239px;
        display: flex;
        align-items: center;
        padding: 19px 25px 21px;
        background: rgba(255, 255, 255, .25);
        margin-bottom: 0;
        transition: var(--transistion);
        border-radius: 0 0 12px 12px;
    }
}

.header__user-account:hover {
    background: rgba(255, 255, 255, .50);

}

.header__user-account-avatar {
    margin-right: 21px;
    display: none;
}

@media only screen and (min-width: 750px) {
    .header__user-account-avatar {
        display: block;
    }
}

.header__user-account-triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid var(--colour__white);
    margin-left: 15px;
    margin-top: 2px;
    display: none;
}

@media only screen and (min-width: 750px) {
    .header__user-account-triangle {
        display: block;
    }
}

.header__user-username {
    font-weight: 500;
    display: inline-block;
}

/**
 *  Footer
 * ------------------------------------------------------------------------- */
.react-login footer {
    padding: 28px 0;
    color:rgba(255, 255, 255, 0.8);
    font-size: 12px;
}

p.footer__content {
    color: var(--colour__white);
    font-size: 12px;
    margin-bottom: 0;
    text-align: center;
}

p.footer__content a {
    display: block;
    margin: 1em 0;
}

@media only screen and (min-width: 750px) {
    p.footer__content {
        text-align: left;
    }

    p.footer__content a {
        display: inline;
        margin: 0;
    }
}

.footer__delimiter {
    display: flex;
    opacity: 0;
}

@media only screen and (min-width: 750px) {
    .footer__delimiter {
        display: inline-block;
        margin: 0 20px;
        opacity: 1;
    }
}

/**
 *  Main
 * ------------------------------------------------------------------------- */
.mainLoginPanel {
    background: var(--colour__white);
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
    padding: 30px;
    text-align: center;
    transition: var(--transition);
}

.mainLoginPanel__top {
    padding: 4px 0 10px;
    text-align: center;
}

@media only screen and (min-width: 850px) {
    .mainLoginPanel__top {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.mainLoginPanel__top-logo {
    margin: 10px 29px;
    width: 321px;
    display: inline-block;
}

h1.mainLoginPanel__top-title {
    margin-bottom: 19px;
    margin-right: 175px;
    font-size: 19px;
    color: var(--colour__font);
    width:310px;
    padding-left: 30px;
}

.mainLoginPanel:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}
 
.mainLoginPanel__top-logo img {
    width: 120px;
    margin-bottom: 20px;
}
 
.mainLoginPanel__top-title {
    font-size: 20px;
    color: var(--colour__primary);
    font-weight: bold;
}

.mainLoginPanel__section {
    width: 90%;
    max-width: 350px;
    margin: 0 auto;
    padding: 24px 20px;
    max-width: 350px;
}

@media only screen and (min-width: 685px) {
    .mainLoginPanel__section {
        padding: 41px 20px;
    }
}

@media only screen and (min-width: 550px) {
    .mainLoginPanel__bottom {
        display: flex;
        border-top: 1px solid var(--colour__font);
        padding: 30px 0 10px;
    }
}

.mainLoginPanel__bottom-column {
    text-align: center;
    font-size: 15px;
}

.mainLoginPanel__bottom-column:first-child {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--colour__font);
    margin-bottom: 16px;
}

.mainLoginPanel:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}
 
.mainLoginPanel__top-logo img {
    width: 120px;
    margin-bottom: 20px;
}
 
.mainLoginPanel__top-title {
    font-size: 20px;
    color: var(--colour__primary);
    font-weight: bold;
}

@media only screen and (min-width: 550px) {
    .mainLoginPanel__bottom-column {
        width: 50%;
        padding: 0 12px;
        font-size: 16px;
    }

    .mainLoginPanel__bottom-column:first-child {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
    }
}

/**
 *  Progress bar
 * ------------------------------------------------------------------------- */
ol.progress__stages {
    width: 100%;
    margin: 0;
    padding: 11px 0;
    display: none;
    align-items: center;
    justify-content: center;
    min-height: 42px;
    color: var(--colour__font);
}

@media only screen and (min-width: 685px) {
    ol.progress__stages {
        display: flex;
        font-size: 1.3rem;
        list-style: decimal;
    }
}

.progress__stage {
    margin: 0 18px;
}

.progress__stage--active {
    color: var(--colour__font);
    /* color: #3A591D; */
    font-weight: 500;
}

.progress__bar {
    background: var(--colour__font);
    /* background: #3A591D; */
    height: 4px;
    display: none;
}

@media only screen and (min-width: 685px) {
    .progress__bar {
        display: block;
    }
}

.progress__bar--20 {
    width: 20%;
}

.progress__bar--40 {
    width: 40%;
}

.progress__bar--60 {
    width: 60%;
}

.progress__bar--80 {
    width: 80%;
}

.progress__bar--100 {
    width: 100%;
}

.progress__bar-step {
    font-size: 14px;
    text-align: center;
    background-color: var(--colour__primary);
    color: var(--colour__white);
    padding: 9px 0 7px;
    margin-bottom: 0 !important;
}

.progress__bar-step.progress__bar-step--final {
    background-color: var(--colour__grey--dark);
}

@media only screen and (min-width: 685px) {
    .progress__bar-step {
        display: none;
    }
}

/**
 *  Button Holder
 * ------------------------------------------------------------------------- */
.button-holder {
    text-align: center;
    padding-bottom: 10px;
}

.button-holder__go-back {
    display: block;
    margin-bottom: 23px;
}

.button-holder__go-back.button-holder__go-back--alt {
    color: var(--colour__secondary);
}

/**
 *  Button
 * ------------------------------------------------------------------------- */
 .button {
    display: inline-block;
    padding: 11px 24px;
    margin: 0 auto 8px;
    border: 0;
    color: var(--colour__white) !important;
    /* background-color: var(--colour__primary); */
    background-color: #3A591D;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
    text-align: center;
    vertical-align: center;
    line-height: 1;
    transition: var(--transistion);
    cursor: pointer;
}

.button:hover {
    opacity: 0.65;
}

.button--extra-wide {
    max-width: 324px;
    width: 100%;
}

.button--inline {
    margin: 0 8px 12px;
    padding: 11px 36px;
}

@media only screen and (min-width: 675px) {
    .button--inline {
        margin: 0 8px 24px;
    }
}

.button--postcode {
    font-size: 15px;
    height: 30px;
    width: 100%;
    padding: 6px 24px;
    margin: 10px auto 0;
}

.cancelLink{
    color: var(--colour__secondary);
    margin: 1em;
    text-decoration: underline;
    display: block;
    margin-bottom: 23px;
}

.cancelLink:visited {
    color: var(--colour__secondary);
    text-decoration: underline;
    display: block;
    margin-bottom: 23px;
}

@media only screen and (min-width: 675px) {
    .button--postcode {
        max-width: 191px;
        margin: 0 auto 0 27px;
    }
}

/**
 *  Modal
 * ------------------------------------------------------------------------- */
.modal__outer {
    width: 100%; height: 100%;
    position: absolute;
    top: 0; left: 0;
    padding: 10px;
    background-color: rgba(255, 255, 255, .5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__inner {
    max-width: 512px;
    width: 100%;
    border: 3px solid var(--colour__primary);
    background-color: var(--colour__white);
    padding: 33px 38px 0;
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .modal__inner {
        margin-top: 165px;
    }
}

.modal__info {
    height: 16px; width: 16px;
    display: inline-block;
    color: var(--colour__secondary) !important;
    border: 1px solid var(--colour__grey--dark);
    text-align: center;
    font-size: 10px !important;
    text-decoration: none !important;
    border-radius: 50%;
    margin-left: 2px;
}
/**
/* Login Overlay
/* ------------------------------------------------------------------------- */

.loginOverlay{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.loginOverlay form{
    display: flex;
    flex-direction: column;
    margin: 20px 50px 20px;
    max-width: 220px;
}

.loginOverlay form label{
    color: var(--colour__font);
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    text-align: center;
    font-size: 14px;
    margin-bottom:6px;
}

.loginOverlay form input{
    height: 30px;
    font-size: 14px;
    color: var(--colour__font);
    background: var(--colour__grey);
    appearance: none;
    border: 0;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
    /* border-radius: 20px; */
}

.loginOverlay form .wrapper {
    display: flex;
}

@media screen and (max-width: 420px) {
    .loginOverlay form{
        display: flex;
        flex-direction: column;
        margin: 0;
        max-width: 220px;
    }
}

.loginOverlay form .wrapper button{
    text-align: center;
    margin-top: 16px;
    margin-bottom: 30px;
    /* border-radius: 20px; */
}

.loginOverlay form .wrapper .cancelLink{
    display: none !important;
    font-size: 14px;
    text-align:center;
    margin-left: 0;
    width: 100%;
}

.loginOverlay form legend{
    max-width: 360px;
    line-height: 16px;
    font-size: 12px;
    color: var(--colour__font);
}

.loginOverlay p{
    max-width: 220px;
    line-height: 16px;
    margin-top: 20px;
    font-size: 12px;
    color: var(--colour__font);
}

/* Back Button*/
button.backButton {
    padding: 0;
    font-size: 14px;
    background-color: transparent;
    color: var(--colour__w3c__primary) !important;
}

hr.backButton {
    margin: 10px 0px 30px 0px;
}

button.backButton:hover {
    color: var(--colour__font) !important;
}

/* Login and Password*/
#passwordChangeFields{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    align-content: flex-start;
}

.passwordField{
    display: block;
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--colour__font);

}

.passwordField span{
    margin-bottom: 6px;
    text-align: center;
    font-weight: 500;
}

.passwordField input{
    width: 86%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    transition: border-color var(--transition);

}
.passwordField input:focus {
    border-color: var(--colour__primary);
    outline: none;
    box-shadow: 0 0 5px rgba(141, 177, 71, 0.3);
}


.changePasswordButton{
    background-color: var(--colour__primary);
    color: var(--colour__white);
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color var(--transition);

}


.changePasswordButton:enabled{
    background-color:#8db248;
    cursor: auto;
    font-size: small;
}

.changePasswordButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
 
.changePasswordButton:hover:not(:disabled) {
    background-color: #76a53a;
}


.changePasswordErrorMessage{
    color: #d9534f;
    font-size: 13px;
    margin-top: 10px;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    padding: 10px;
    border:1px solid #f00;
    background-color: #ffebe6;
    color: #f00;
    overflow: hidden;
    box-sizing: border-box;

}

#passwordChangeSuccessMessage{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: #f0f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background: var(--colour__white);
    border-radius: 8px;
    max-width: 90%;
    padding: 30px;
    text-align: center;
    transition: var(--transition);
    width: 900px;
    margin-top: 118px;
    margin-left: 190px;

}


.passwordChangeSuccessMessageText{
    font-size: 16px;
    color: var(--colour__primary);
    font-weight: bold;

    font-size: 16px;
    color: var(--colour__primary);
    font-weight: bold;

}

.passwordChangeSuccessMessageButton{
    margin-top: 20px;
    width: 100px;
    height: 42px;
    font-size: small;
    background-color: #52a352;
}
.passwordChangeSuccessMessageButton:hover {
    background-color: #bd5196;
}
.CheckImageForPasswordChange {
    width: 60px;
}

.dashboard-ctas__icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    border-radius: 50%;
    background: white;
}

.dashboard-ctas__icon svg{
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}