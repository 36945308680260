.clinic-profile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinic-profile-modal {
  position: relative;
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.clinic-profile-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #000000;
}

.clinic-profile-modal-content {
  vertical-align: middle;
  height: auto;
}
