.react-shared-table .clickable {
  cursor: pointer;
}

.react-shared-table .clickable:hover {
  background-color: #f0f0f0; /* Change the background color on hover */
}

.react-shared-table table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  font-family: verdana, Calibri, Arial;
  font-size: 10pt;
}

.background-color tbody {
  background-color: #badb8483;
}

.omit-last-column-background-color-td td:not(:last-child) {
  background-color: #badb8483;
}

.react-shared-table th {
  background-color: #d75093d5;
  color: #333;
  font-weight: bold;
}

.react-shared-table th,
.react-shared-table td {
  text-align: center;
  padding: 8px;
  vertical-align: middle;
}

.react-shared-table th:first-child {
  border-top-left-radius: 10px;
}

.react-shared-table th:last-child {
  border-top-right-radius: 10px;
}

.react-shared-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.react-shared-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.omit-rounding-last-td-bottom-right tr:last-child td:nth-last-child(2) {
  border-bottom-right-radius: 10px;
}

.no-data-background td {
  background-color: #badb84b2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.wide-column {
    width:300px;
    white-space: normal;
    word-wrap: break-word;
 }
 