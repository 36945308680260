.search-box {
  width: 200px; /* Set width */
  height: 30px; /* Set height */
  padding: 5px; /* Add padding */
  border: 1px solid #ccc; /* Add border */
  border-radius: 5px; /* Add border radius */
  background-color: #fff; /* Set background color */
  font-size: 14px; /* Set font size */
  color: #333; /* Set text color */
  outline: none; /* Remove outline */
}