.expand-collapse-icon-resize {
  width: 30px;
  height: auto; /* Maintain aspect ratio */
  opacity: 0.7;
  cursor: pointer;
}
.expand-collapse-icon-resize {
  transition: transform 0.3s ease-in-out; /* Adding transition effect */
}

.rotate90 {
  transform: rotate(90deg); /* Rotating the image by 180 degrees */
}
