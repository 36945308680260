/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * neo-sans:
 *   - http://typekit.com/eulas/00000000000000003b9b2033
 *   - http://typekit.com/eulas/00000000000000003b9b2035
 *   - http://typekit.com/eulas/00000000000000003b9b2038
 *   - http://typekit.com/eulas/00000000000000003b9b2039
 *   - http://typekit.com/eulas/00000000000000003b9b203a
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-09-03 22:10:08 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=sit2kvo&ht=tk&f=39475.39477.39480.39481.39482&a=85759686&app=typekit&e=css");

@font-face {
font-family:"neo-sans";
src:url("https://use.typekit.net/af/00ac0a/00000000000000003b9b2033/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/00ac0a/00000000000000003b9b2033/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/00ac0a/00000000000000003b9b2033/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"neo-sans";
src:url("https://use.typekit.net/af/6716c2/00000000000000003b9b2035/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/6716c2/00000000000000003b9b2035/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/6716c2/00000000000000003b9b2035/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"neo-sans";
src:url("https://use.typekit.net/af/e8ba57/00000000000000003b9b2038/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/e8ba57/00000000000000003b9b2038/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/e8ba57/00000000000000003b9b2038/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"neo-sans";
src:url("https://use.typekit.net/af/39b711/00000000000000003b9b2039/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/39b711/00000000000000003b9b2039/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/39b711/00000000000000003b9b2039/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"neo-sans";
src:url("https://use.typekit.net/af/8870bf/00000000000000003b9b203a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/8870bf/00000000000000003b9b203a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/8870bf/00000000000000003b9b203a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

.tk-neo-sans { font-family: "neo-sans",sans-serif; }

.tabbed-form{
    /* position: relative; */
    z-index: 1;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    width: auto;
    min-width: 0;
    padding: 12px;
    background: #f0f0f0;
    font-size: 14.5px;
}

.eform .tabbed-form ul.iprs-tab{
    margin: 0 0 0 0;
    padding: 0;
    list-style-type: none;
    color: white;
}

.tabbed-form .form__field{
    margin-top: 15px;
}

.typography{
    color: #646363;
    /* font-size: 1rem; */
    line-height: 1.5;
}

ul.iprs-tabs{
    margin: 0 0 0 0 !important; 
    padding: 0 !important;
    list-style-type: none !important;
    color: white;
}

.iprs-tab-list-item{
    /* display: inline-block; */
    margin-right: 4px;
    margin-bottom: 6px;
}

.iprs-tab.iprs-tab-selected{
    border-color: #8db248;
    background: #8db248;
}

.iprs-tab.iprs-tab-selected:hover{
    background-color: rgba(215, 81, 148, 0.849);
    -webkit-transition: border-color .3s ease,color .3s ease,background-color .3s ease;
    transition: border-color .3s ease,color .3s ease,background-color .3s ease;
}

.iprs-tab-list-item-invalid-icon {
    color: red;
    padding-left: 0.5em;
    font-size: 120%;
}

@media screen and (min-width: 768px){
    .iprs-tab-list-item{
        max-width: 150px;
    }
    ul.iprs-tabs{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.iprs-tab-list-item a.iprs-tab:link {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 13.5px;
    line-height: 1.4;
    font-weight: 500;

    padding: 10px 20px;
    height: 100%;
    color: #fff;
    background-color: #d75195;
    font-family: neo-sans,verdana,sans-serif;
    text-align: center;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: border-color .3s ease,color .3s ease,background-color .3s ease;
    transition: border-color .3s ease,color .3s ease,background-color .3s ease;
}

.iprs-tab.iprs-tab-selected{
    text-decoration: none;
    color: white;
}

.iprs-tab-list-item a.iprs-tab:hover, .iprs-tab-list-item a.iprs-tab.iprs-tab-selected {
    background-color: rgba(141, 178, 72, 0.5);
    -webkit-transition: border-color .3s ease,color .3s ease,background-color .3s ease;
    transition: border-color .3s ease,color .3s ease,background-color .3s ease;
}

.iprs-tab-list-item a.iprs-tab.iprs-tab-selected {
    background-color: rgba(141, 178, 72, 0.8);
}

.form__label{
    min-width: 120px;
    font-weight: 700;
    display: block;
}

.form__field.form__field--full-width > input{
    font-size: 12px;
    /* appearance: none; */
    margin: 0;
    padding: 6px;
    border: 1px solid #9d9d9c;
    border-radius: 0;
    color: #000;
    background: #fff;
    font: 14px neo-sans,verdana,sans-serif;
    height: auto;
    width: 100%;
    max-width: 480px;
}

.form__dropdown-wrapper::after{
    position: relative;
    content:'';
    color: red;
    top: 14px;
    right: 24px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-color: #d75195 transparent transparent transparent;
    pointer-events: none;
}

.form__dropdown{
    appearance: none;
    max-width: 480px;
    padding: 6px;
    width: 100%;
    margin: 0;
    padding: 6px;
    border: 1px solid #9d9d9c;
    border-radius: 0;
    color: #000;
    background: #fff;
    font: 14px neo-sans,verdana,sans-serif;
}


/* Radio buttons for vertical and horizontal list of labels */

.form__radio-wrapper {
    display: flex;
}

.form__radio-wrapper.vertical {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.form__radio-wrapper.horizontal .form__radioInput{
    margin-right: 20px;
    margin-top: 6px;
}

.form__radio-wrapper.vertical .form__radioInput{
    margin-top: 6px;
}

.form__radio-wrapper.vertical .form__radioInput input[type=radio]{
    padding: 0;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 20px;
    /* margin-top: 6px; */
    width: auto;
    transform: scale(1.1);
}

/* Questionnaire Form Styling*/
.questionnaireForm .form__field.form__field--full-width{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.questionnaireForm .form__field.form__field--full-width .form__label{
    width: auto;
}

.questionnaireForm .form__field.form__field--full-width .form__radio-wrapper {
    margin-top: 8px;
    width: auto;
}

.questionnaireForm .form__radioInput{
    display: flex;
}

.questionnaireForm .form__radioInput label{
    width: auto;
    margin: 0 0 0 10px;
    font-weight: 400;
}

.questionnaireForm .form__radio-wrapper{
    display: flex;
    flex-direction: column;
}

.tableQuestion{
    width: 400px;
}

.tableRadioButton{
    width: 100px;
    text-align: center;
}

.tableRadioButton input[type="radio"]{
    transform: scale(1.2);
}

/* Tabled Questionnaire Form Styling*/

.tabledQuestionnaireForm{
    margin-top: 30px;
}

.tabledQuestionnaireForm tr.questionRow th{
    text-align: center;
    padding: 0;
    border-color:transparent;
}

.tabledQuestionnaireForm tr.questionRow:nth-child(2) th:nth-child(n+2){
    color: #fff;
    background-color: #d75195;
}

.tabledQuestionnaireForm tr.questionRow:nth-child(1) th:nth-child(even){
    color: #fff;
    background-color: #8db248;
}

.tabledQuestionnaireForm td.tableQuestion {
    padding: 15px 0px 0px 0px;
    border-color: transparent;
}

.tabledQuestionnaireForm td.tableRadioButton {
    padding: 15px 0 15px 0;
    text-align: center;
    vertical-align: middle;
    border-color: transparent;
}

.tableRadioButton input[type=radio]{
    width: 100%;
    text-align: center;
    margin: 0;
}


/* Disabled Text Input Field */

.form__textarea {
    padding: 6px;
    background: #fff;
    border: 1px solid #9d9d9c;
    max-width: 480px;
    width: 100%;
    font: 14px neo-sans,verdana,sans-serif;
}

.form__input {
    background-color: white;
    height: 20px;
    border: 1px solid var(--colour__grey--dark);
}

textarea:disabled.form__textarea {
    background-color: var(--colour__grey--light);
}

input:disabled.form__input {
    background-color: var(--colour__grey--light);
}


/* Percentage Slider */

.form__field.form__field--full-width.form__slider {
    display: flex;
    max-width: 480px;
}

.form__field.form__field--full-width.form__slider > label {
    display: flex;
    align-items: center;
}

.form__field.form__field--full-width.form__slider > p {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.form__slider__value{
    min-width: 50px;
}

/*********** Baseline, reset styles ***********/
.form__field.form__field--full-width > input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    border: 0px;
    padding: 0;
  }
  
    /******** Chrome, Safari, Opera and Edge Chromium styles ********/
    /* slider track */
    .form__field.form__field--full-width > input[type="range"]::-webkit-slider-runnable-track {
        background-color: #d9d9d9;
        border-radius: 1rem;
        height: 1rem;
    }
  
    /* slider thumb */
    .form__field.form__field--full-width > input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: -5px; /* Centers thumb on the track */
        background-color: #8db147;
        border-radius: 50%;
        height: 20px;
        width: 20px;
    }
  
     /*********** Firefox styles ***********/
    /* slider track */
    .form__field.form__field--full-width > input[type="range"]::-moz-range-track{
        background-color: #d9d9d9;
        border-radius: 1rem;
        height: 1rem;
    }
  
    /* slider thumb */
    .form__field.form__field--full-width > input[type="range"]::-moz-range-thumb {
        background-color: #8db147;
        border: none; /*Removes extra border that FF applies*/
        border-radius: 50%;
        height: 20px;
        width: 20px;
    }

/* Tab button */
.tabPrevNextButtons {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.tabPrevNextButtons a {
    cursor: pointer;
}


/* Check boxes */
.form__field.form__field--full-width>input[type='checkbox']{
    width: 12px;
    height: 12px;
    border-radius: 3px;
    transform: scale(1.4);
    margin-top: 12px;
    margin-left: 2px;
}

.form__field.form__field--full-width>input[type='checkbox']:checked{
    background-color: #0075FF;
    
}

.form__field.form__field--full-width>input[type="checkbox"]:checked + label {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.form__field.form__field--full-width>input[type="checkbox"]:checked + label:after {
    content: "\2705";
    color: white;
    display: inline-block;
}

/* highlighting rule put in TICKET-368 as missing in pharos */
input.form__input.input-validate-highlight, 
select.form__dropdown.input-validate-highlight,
input.input-validate-highlight
{
    border-color: red; /*var(--colour__primary); */
    border-width: 1px;
    border-style: solid;
    outline: 0;
}

/* Alert Warning Box*/
.alert.required{
    margin-top: 10px;
}

.saveLink{
    cursor: pointer;
    text-decoration: underline;
}


/* Timer and Save Button */

.saveButtonAndTimer{
    display: flex;
    justify-content: center;
}

.saveButtonAndTimer p {
    background-color: #f7deeb;
    color: #d75195;
    border:#d75195 1px solid;
    width: fit-content;
    padding: 10px;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    font-size: 14px;
}

.tabPrevNextButtons .button:disabled{
    color: #efefefba;
    background-color: #d7519552;
    border: 1px solid #d7519547;
}


/* Loader for */
.loader{
    position: absolute;
    width:100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.loaderBackground {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Warning box*/
.alert.required {
    border: 1px solid #bd5196;
    color: #843969;
    background: #f8eef5;
}

.alert {
    display: block;
    margin-bottom: 1em;
    padding: 0.8em 1em 0.7em;
    border: 1px solid #9d9d9c;
    font-size: 14px;
    line-height: 1.3;
}



@media only screen and (max-width: 960px) {
    .form__field.form__field--full-width.assessment{
        float: none;
        max-width: 100%;
    }
    
    .form__field.form__field--full-width.goal{
        float: none;
    }
}
