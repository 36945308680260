.rotate {
  animation: rotateAnimation 3s infinite linear;
  width: 50px;
  height: 50px;
  position: center;
}

.static {
  width: 50px;
  height: 50px;
  position: center;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-rounded-corners-100-by-100 {
  width: 100px;
  height: 100px;
  background-color: #badb84b2;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.react-shared .container-centre-component {
  position: relative;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

