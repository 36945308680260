.navigateToFirstPage {
  width: 25px;
  height: 25px;
  display: inline-block;
  opacity: 0.5;
}

.navigateToFirstPageDisabled {
  width: 25px;
  height: 25px;
  display: inline-block;
  opacity: 0.3;
}

.navigateBackwards {
  width: 25px;
  height: 25px;
  display: inline-block;
  opacity: 0.5;
}

.navigateBackwardsDisabled {
  width: 25px;
  height: 25px;
  display: inline-block;
  opacity: 0.3;
}

.navigateForwards {
  width: 25px;
  height: 25px;
  display: inline-block;
  opacity: 0.5;
}

.navigateForwardsDisabled {
  width: 25px;
  height: 25px;
  display: inline-block;
  opacity: 0.3;
}

.navigateToLastPage {
  width: 25px;
  height: 25px;
  display: inline-block;
  opacity: 0.5;
}

.navigateToLastPageDisabled {
  width: 25px;
  height: 25px;
  display: inline-block;
  opacity: 0.3;
}

.current-page {
  color: #d75094;
}
