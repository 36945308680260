.react-shared-InLine p,
.react-shared-InLine input,
.react-shared-InLine label,
.react-shared-InLine select,
.react-shared-InLine div,
.react-shared-InLine textarea {
  display: inline;
  margin: 0;
  font-family: verdana, Calibri, Arial;
  font-size: 10pt;
  color: #333;
}

.react-shared-inLine {
  display: inline;
  font-family: verdana, Calibri, Arial;
  font-size: 10pt;
  color: #333;
}

.react-shared  .flex {
  display: flex;
}

.react-shared p,
.react-shared a,
.react-shared label,
.react-shared span,
.react-shared input {
  font-family: verdana, Calibri, Arial;
  font-size: 10pt;
  color: #333;
}

.react-shared a:hover {
font-family: verdana, Calibri, Arial;
  font-size: 10pt;
  color: #d75094;
}

.react-shared h1,
.react-shared h2,
.react-shared h3 {
  font-family: verdana, Calibri, Arial;
  color: #333;
}

.react-shared h3 {
  font-size: medium;
  padding-bottom: 0.8rem;
}

.react-shared h2 {
  font-size: large;
  padding-bottom: 0.8rem;
}

.react-shared strong {
  font-family: verdana, Calibri, Arial;
  font-size: 10pt;
  color: #d75094;
}

.react-shared b {
  font-family: verdana, Calibri, Arial;
  font-size: 10pt;
  color: #d75094;
}

.react-shared input {
  line-height: normal;
  border-radius: 5px;
}

.react-shared input[type="date"],
.react-shared input[type="time"],
.react-shared input[type="datetime-local"],
.react-shared input[type="month"] {
  /* This has an undesired interaction with a bootstrap style in pharos */
  line-height: normal; /* Override line-height */
}

.react-shared .top-aligned-textarea {
  vertical-align: top;
}

.react-shared .InlineBlock {
  display: inline-block;
  white-space: nowrap;
  margin-left: 5px;
}

.react-shared .padding10 {
  padding-top: 10px;
}

.react-shared .padding2 {
  padding: 2rem 2rem 0rem 2rem;
}

.react-shared .padding-top-2 {
  padding-top: 2rem;
}

.react-shared .container-centre-column {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.react-shared .container-inline {
  display: inline-block;
}

.react-shared .container-justified-left {
  justify-content: left;
}

.react-shared .container-30-align-left {
  width: 30%;
  margin-left: auto;
}

.react-shared .container-50-align-left {
  width: 50%;
  margin-left: auto;
  display: flex;
}

.react-shared .container-25 {
  width: 25%;
}

.react-shared .container-35 {
  width: 25%;
}

.react-shared .container-50 {
  width: 50%;
}

.react-shared .container-75 {
  width: 75%;
}

.react-shared .container-100 {
  width: 100%;
}

.react-shared .container-100-border-box {
  width: 100%;
  box-sizing: border-box;
}

.react-shared .container-100-justified-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 0.2rem;
}

.react-shared .text-input-outline {
  outline: 1px solid #ccc;
  padding: 10px;
}

.react-shared button {
  background-color: #ededed;
  border-color: #9db0a9;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  font-weight: bold;
  text-decoration: none;
  color: #d75094;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  height: auto !important;
  width: auto !important;
  vertical-align: middle;
  display: inline-block;
}

#dynamicInput {
  width: auto;
  max-width: 100%; /* Ensure it doesn't exceed the container width */
  box-sizing: border-box; /* Include padding and border in the width calculation */
}

.react-shared .clickable {
  cursor: pointer;
}