.tab-buttons {
  display: flex;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: #333; /* nice text colour, super dark grey */
  position: relative;
}

.tab::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: transparent; /* make sure line colour is transparent to begin with */
  transition: background-color 0.3s ease; /* buttery smooth transition */
}

.tab.active::after {
  background-color: #d75094; /* Color of the bottom line for active tab */
}
