.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: #f0f0f0; /* Change the background color on hover */
}
.service-div {
    display: block;
    margin-bottom: 15px; 
    text-align: justify;
}

.clinic-profile table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  font-family: verdana, Calibri, Arial;
  font-size: 8pt;
  border: 1px solid black;
  margin-bottom: 10px;
}
.service-profile table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    font-family: verdana, Calibri, Arial;
    font-size: 8pt;
    border: 1px solid white;
    margin-bottom: 10px;
  }
.ColumnPadding {
    padding-left: 5px;
    padding-right: 5px;
}

.ColumnHeaderStyle {
    background-color: #C95191;
    color: #FFFFFF;
    font-weight: bold;
}
.Summary table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    font-family: verdana, Calibri, Arial;
    font-size: 5pt;
}

.background-color tbody {
  background-color: #badb8483;
}

.omit-last-column-background-color-td td:not(:last-child) {
  background-color: #badb8483;
}


.clinic-profile th {
  background-color: #d75093d5;
  color: #333;
  font-weight: bold;
}

.service-profile th {
    background-color: #d75093d5;
    color: #333;
    font-weight: bold;
  }
.clinic-profile tr:hover {
    background-color: #e5e0e3d5;
  }
  .service-profile tr:hover {
    background-color: #e5e0e3d5;
  }
.altPostcodeLabel {
    display: flex;
    flex-direction: column;
  }
  
  .altPostcodeLabel input {
    margin-top:4px;
    width: 100%;
  }
  
  .altPostcodeLabel div{
    display: flex;
    flex-direction: row;
  }
  
  .altPostcodeLabel div span{
    min-width: 250px;
  }
  
.clinic-profile th,
.clinic-profile td {
  text-align: center;
  padding: 2px;
  vertical-align: middle;
  font-size: 9pt;
  transition: background-color 0.3s;
}
.service-profile th,
.service-profile td  {
  text-align: center;
  padding: 2px;
  vertical-align: middle;
  font-size: 9pt;
  transition: background-color 0.3s;

}

.inner-element{

    width:80px
}

.clinic-profile th:first-child {
  border-top-left-radius: 10px;
}

.clinic-profile th:last-child {
  border-top-right-radius: 10px;
}

.clinic-profile tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.clinic-profile tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.service-profile th:first-child {
    border-top-left-radius: 10px;
  }
  
  .service-profile th:last-child {
    border-top-right-radius: 10px;
  }
  
  .service-profile tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  
  .service-profile tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
.omit-rounding-last-td-bottom-right tr:last-child td:nth-last-child(2) {
  border-bottom-right-radius: 10px;
}


.service-profile td {
    width: 200px; 
    overflow: hidden; 
    white-space: nowrap; 
    border: 1px solid #000; 
}
 

.service-profile td * {
    display: inline-block; 
    max-width: 100%;
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    box-sizing: border-box; 
}
 

.service-profile {
    width: 100%;
    table-layout: fixed;
}
 
.service-profile th, .service-profile td {
    padding: 5px; 
    text-align: center;
}
 

.service-profile td .inner-element {
    width: 80px; 
    max-width: 100%;
}

.wide-column {
   width:300px;
   white-space: normal;
   word-wrap: break-word;
}

.service-profile colgroup col{
    width: 100px;
}

.service-profile colgroup col.wide-column{
    width: 250px;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
}