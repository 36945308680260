/* Styles for the toggle switch */
.clinic-profile-switch {
  position: relative;
  display: inline-block;
  width: 2.5rem; /* Adjusted width in rem */
  height: 1.5rem; /* Adjusted height in rem */
  vertical-align: middle;
  top: 0.5rem;
}

/* Hide default checkbox */
.clinic-profile-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Style the slider */
.clinic-profile-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s; /* No vendor prefix so should work in edge and chorome*/
  border-radius: 0.75rem; /* Adjusted border-radius in rem */
}

/* Rounded slider */
.clinic-profile-slider.clinic-profile-round {
  border-radius: 0.75rem;
}

/* Style the slider when it's in the checked (on) state */
.clinic-profile-slider:before {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  left: 0.15rem;
  bottom: 0.135rem;
  background-color: white;
  transition: 0.4s; /* No vendor prefix so should work in edge and chorome*/
  border-radius: 50%;
}

/* Style the slider when it's in the checked (on) state */
input:checked + .clinic-profile-slider {
  background-color: #d75094;
  transform: translateY(0.4rem);
  transition: 0;
}

/* Move the slider to the right (on) position */
input:checked + .clinic-profile-slider:before {
  transform: translateX(
    1rem
  ); /* Adjusted translation in rem without vendor prefix again for multiple browser compatability */
}
