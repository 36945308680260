

/* ----------------------------------------------------------------------------
    Case Controls for hold and case cancel 
---------------------------------------------------------------------------- */

div.CaseControls {
    opacity: 1;
}

.CaseControlButton {
    width: 175px;
}

/* ----------------------------------------------------------------------------
Overlay for case on hold and case cancel 
---------------------------------------------------------------------------- */
div.OverlayWrapper {
    position: fixed;
    width: 100%; 
    height: 100%;
    top: 0; 
    left: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    float: none;
    background-color: rgb(255, 255, 255, 0.1);
}

div.Overlay {
    /* width: 50em;
        height: 10em; */
    display: block;
    text-align: center;
    margin: 0;
    background-color: white;
    padding: 1em;
    border: solid grey 1px;
    border-radius: 1em;
}

/* ----------------------------------------------------------------------------
Form stuff used in case on hold and case cancel 
---------------------------------------------------------------------------- */

.form fieldset {
    display: contents;
}

.form legend, .form fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.form legend {
    float: left;
    padding: 1em 0;
    font-weight: bold;
}

.form textarea, .form button, .form input, .form label, .form legend {
    float: left;
    clear: left;
    text-align: left;
} 

.form textarea {
    min-width: 100%;
    min-height: 4em;
}

.form .form__field {
    margin-bottom: 1em;
    display: block;
    float: left;
    clear: left;
}
.form .form__field--full-width{
    width: 100%;;
}

.form .buttonWrapper{
    float: left;
    clear: both;
}
.form .buttonWrapper button {
    float: left;
    clear: none;
}
.form .buttonWrapper button:first-child{
    margin-right: 1em;
}

textarea:invalid, input:invalid {
    border-color: red;
}

.validation-warning {
    visibility: hidden;
    float: left;
    clear: left;
    color: red;
}

textarea:invalid + .validation-warning{
    visibility: visible;
    color: blue;
}

textarea:valid + .validation-warning{
    visibility: visible;
    color: yellow;
}

/* ----------------------------------------------------------------------------
Onward referral stuff
---------------------------------------------------------------------------- */

.onwardReferralSearchForm{
  margin: 10px;
  max-width: 270px;
}

.searchHeading{
  font-size: 22px;
}

/* width */
.searchSection::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.searchSection::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.searchSection::-webkit-scrollbar-thumb {
  background: #a1a1a1;
}

/* Handle on hover */
.searchSection::-webkit-scrollbar-thumb:hover {
  background: #2e2e2e;
}


.onwardReferralSearchForm label{
  display: flex;
  margin-top: 6px;
}

.onwardReferralSearchForm label span{
  min-width: 250px;
}

.onwardReferralSearchForm label input.altPostcode{
  display: flex;
}

.altPostcodeLabel {
  display: flex;
  flex-direction: column;
}

.altPostcodeLabel input {
  margin-top:4px;
  width: 100%;
}

.altPostcodeLabel div{
  display: flex;
  flex-direction: row;
}

.altPostcodeLabel div span{
  min-width: 250px;
}

.searchAndMoreButton {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.searchAndMoreButton .searchPracticeButton{
  margin-top: 30px;
  width: 270px;
  border-radius: 20px;
  cursor:pointer;
  font-size: 17px;
  border-radius: 20px;
  color: white;
  background-color:#DE7AAD;
  border: 1px solid #f1f1f1;
  padding: 6px;
}

.searchAndMoreButton .searchPracticeButton:hover{
  background-color:#f1f1f1;
  border: 1px solid #DE7AAD;
  color: #DE7AAD;
}

.searchAndMoreButton .searchPracticeButton:disabled{
  cursor: default;
  background-color:#f1f1f171;
  border: 1px solid #de7aae80;
  color: #de7aae77;
}

.labelPostcode{
  font-size: 12px;
}


.distanceInput {
  display: flex;
}

.distanceInput .distanceSlider{
  width:220px;
}

.distanceInput .distanceText{
  margin-left: 8px;
  width:30px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid grey;
}

.SearchTextInputFullSize{
  width: 262px;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid grey;
}

.searchClinic{
  width: 280px;
}

.clinicList{
  margin: 10px;
  font-size: 14px;
}

.clinicList h2{
  font-size: 24px;
  text-align: center;
}

.clinicCard{
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 5px;
  padding: 12px;
}

.onwardReferralLoader{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: auto;
}

.onwardReferralLoader div{
  display: flex;
  align-items:center;
  flex-direction:column;
  color: white;
}

.clinicCardRow {
  display: flex;
  flex-direction: row;
}

.clinicCardRow h3{
  margin: 0px 0px 0px 10px;
}

.searchFormMoreButton{
  border: 1px solid transparent;
  background-color: transparent;
}

.searchFormMoreButton:hover{
  border: 1px solid rgb(194, 194, 194);
  background-color: rgb(241, 241, 241);
} 

.makeReferralMessage{
  position: fixed;
  width: 100%;
  height: 100%;
  /* position: absolute;
  width: 100vw;
  height: 100vh; */
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: auto;
  top: 0;
  left: 0;
}

.referralMessageBoard{
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  max-width:400px;
}

.referralMessageBoardForm{
  display:flex;
  flex-direction: column;
}

.clinicName{
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 700;
}

.notesToAP{
  display:flex;
  flex-direction: column;
  font-weight: 400;
}

.notesCharacterRemaining {
  font-size: 12px;
  text-align: right;
}

.notesCharacterRemaining .alert{
  color: red;
}

.onwardReferralMainContent{
  display:flex;
}

.onwardReferralSearchSection{
  /* flex: 25%; */
  min-width: 250px;
  max-width: 400px;
  height: calc( 100vh - 200px );
  overflow: auto;
}

.mapSection{
  flex: 65%;
  /* min-width: 500px; */
  /* height: 900px; */
  overflow: auto;
}

.mapSection .onwardReferralLegend{
  /* height: 10%; */
  display: block;
  min-height: 80px;

  flex-direction: column;
}

/* Legend Markers*/

.legendIcons{
  width: auto;
  height: 20px;
}


.legendMarkerLabel{
  display: flex;
  flex-direction: row;
  margin:4px 6px 4px 8px;
  padding: 5px 10px 5px 10px;
  border: 1px solid #DE7AAD;
  border-radius: 20px
}

.legendMarkerLabels{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.legendMarkerLabel div{
  height: 20px;
  font-size: 14px;
}

.legendMarkerIcon {
  display: flex;
  align-items: center;
}

.legendMarkerIconLabel {
  display:flex;
  align-items: center;
  margin: 0px 10px 0px 6px;
}

.legendMarkerLabel{
  display:flex;
}

.clinicCardRowClinicName{
  display: flex;
}

.clinicIcon{
  width:20px;
}

.clinicListCardDistanceAndDuration{
  font-size: 14px;
  margin: 0px 0px 0px 0px;
}

.clinicListCardClinicName{
  width: auto;
}

.emailAndPhone{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 14px;
  margin-top: 14px;
  width: auto;
}

.emailAndPhone div{
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
}

.emailAndPhone div div {
  margin-top: 0px;
  margin-left: 10px;
  width: 100%;
}

.makeReferralButton{
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.makeReferralButton button{
  padding: 5px;
  margin-bottom: 8px;
  font-size: 14px;
  border-radius: 20px;
  color: white;
  background-color:#DE7AAD;
  border: 1px solid #f1f1f1;
  padding: 10px;
  cursor: pointer;
}

.makeReferralButton button:hover{
  background-color:#f1f1f1;
  border: 1px solid #DE7AAD;
  color: #DE7AAD;
}

.clinicFees{
  display:flex;
}

.clinicFees img{
  margin-top: 2px;
  height: 20px;
  width: 18px;
}

.clinicFees table{
  margin-left: 6px;
}

.clinicAddressAndDistance{
  display:flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}

.clinicAddressAndDistance .clinicAddress{
  display: flex;
  flex-direction: row;
  width:80%;
}

.clinicAddressAndDistance .clinicAddress img{
  width: 20px;
}

.clinicAddressAndDistance .clinicAddress p{
  padding: 0px;
  margin: 0px 0px 0px 10px;
}

.clinicAddressAndDistance .homeAndWorkDistanceAndTime{
  font-size:12px;
  width:20%;
  display:flex;
  flex-direction: column;
}

.clinicAddressAndDistance .homeAndWorkDistanceAndTime div{
  display:flex;
  flex-direction: column;
  align-items: center;
  text-align:center;
}

.clinicAddressAndDistance .homeAndWorkDistanceAndTime div div{
  margin-top: 4px;
}

.clinicCard hr{
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.referralMessageWindowWarning{
  color: red;
  font-size: 14px;
}

.referralMessageBoardFormCloseButton{
  display:flex;
  flex-direction: column;
  align-items: flex-end;
}

.referralMessageBoardCloseButton{
  display: flex; 
  flex-direction: column;
  align-items: center;
  border: none;
  background: none; 
  cursor: pointer;
  padding-top:5px;
  padding-bottom:5px;
}

.referralMessageBoardCloseButton:hover{
  background-color: grey;
  text-decoration:underline;
  color: white;
}

.makeReferralNoteTextArea{
  padding: 10px;
  border-radius: 10px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  border: 1px solid grey;
}

.makeReferralResultErrorMessage{
  color: red;
  font-size: 14px;
}

.SuccessMessageIcon{
  width: 100px;
  height: auto;
}

.SuccessMessageButton{
  font-size: 18px;
}

.makeReferralMessageInputFields{
  display: block;
  margin-bottom: 2ex;
}

.makeReferralMessageInputFields span{
  padding: 2px;
  display: block;
  float: left;
}

.makeReferralMessageInputFields input, 
.makeReferralMessageInputFields select{
    float: right;
    padding: 4px;
    display: block;
}

.makeReferralMessageInputFields input{
  border-radius: 5px;
  width: 20px;
  height: 20px;
}

.makeReferralMessageInputFields span strong{
    display: inline-block;
    margin-top: 5px;
    font-size: 120%;
    color: red;
}

.makeReferralMessageInputFields span em{
    font-weight: bold;
}

.onwardReferralMapInfoWindow{
  max-width: 150px;
}

.onwardReferralMapInfoWindow button{
  padding: 5px;
  margin-bottom: 8px;
  font-size: 14px;
  border-radius: 20px;
  color: white;
  background-color:#DE7AAD;
  border: 1px solid #f1f1f1;
  padding: 10px;
  cursor: pointer;
}

.onwardReferralMapInfoWindow button:hover{
  background-color:#f1f1f1;
  border: 1px solid #DE7AAD;
  color: #DE7AAD;
}

.onwardReferralBackToSearchButton{
  font-size: 14px;
  border-radius: 20px;
  color: black;
  background-color: rgba(0,0,0,0);
  border: 1px solid rgba(0,0,0,0);
  padding: 6px;
  cursor: pointer;
}

.onwardReferralBackToSearchButton:hover{
  color:#DE7AAD;
  text-decoration: underline;
}

.onwardReferralSave {
  display:flex;
  justify-content: center;
}

.onwardReferralSaveButton{
  padding: 5px;
  margin-bottom: 8px;
  font-size: 14px;
  border-radius: 20px;
  color: white;
  background-color:#DE7AAD;
  border: 1px solid #f1f1f1;
  padding: 10px 30px 10px 30px;
  cursor: pointer;
  margin-top: 10px;
}

.onwardReferralSaveButton:disabled{
  background-color:#de7aae62;
  border: 1px solid #f1f1f186;
  color: rgba(255, 255, 255, 0.637);
}

.onwardReferralSaveButton:enabled:hover{
  background-color:#f1f1f1;
  border: 1px solid #DE7AAD;
  color: #DE7AAD;
}

.makeReferralResultErrorMessage{
  font-size: 10px;
}

.makeReferralStatusDiv{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.CaseControlButton {
    width: 175px;
}

.searchResultStatus{
  text-align: center;
}

.errorMessage {
  color: red;
  font-size: 16px;
}

.makeReferralMessageInputFields.justificationDropDownField{
  display:flex;
  flex-direction: column;
  margin-top: 10px;
}

.makeReferralMessageInputFields.justificationDropDownField select{
  margin-top: 4px;
}

.disabilityAccessAndCarParking{
  margin-top: 6px;
  display: flex;
  flex-direction: row;
}

.disabilityAccessAndCarParking div{
  width: 50%;
}

.disabilityAccessAndCarParking div label{
  display:flex;
  flex-direction: row;
}

/* .disabilityAccessAndCarParking div label .labelDivName{
  width: 70%;
}

.disabilityAccessAndCarParking div label .labelDivContent{
  width: 30%;
} */

.openingTimeTitle{
  width: 18px;
  height: 20px;
}

.openingHoursTable{
  margin-top: 10px;
}

.openingHoursTable label{
  display: flex;
}

.openingHoursTable tbody{
  margin-left: 10px;
}

.onwardReferralWebAddressTitle{
  font-weight: 500;
}

.onwardReferralWebAddressAnchor{
  color: black;
  text-decoration: none;
}

.onwardReferralWebAddressAnchor:hover{
  text-decoration: underline;
}

.onwardReferralWebAddress label{
  display:flex;
  flex-direction: row;
}

.onwardReferralWebAddressAnchor{
  margin-left: 6px;
}

.onwardReferralWebAddress{
  margin-top: 6px;
}

.labelDivName{
  font-weight: 500;
}

.clinicIcon{
  width: 18px;
  height: 20px;
}

.clinicFees td{
  text-align: left;
}

.openingTimeContent td{
  text-align: left;
}

.onwardReferralMapInfoWindow p{
  margin: 0px 0px 2px 0px;
}

.onwardReferralMapInfoWindow button{
  margin-top: 20px;
}

/* Web Referral Config List */
.formListItemDiv{
    background-color:rgb(233, 233, 233);
    border:#2e2e2e 1px ;
    padding: 12px;
    margin:2px;
    margin-bottom: 10px;
    border-radius:7px;
}

.formListItemDiv h3{
    color: #BD5196;
}

.formVersions{
    padding: 5px;
    background-color:rgb(224, 224, 224)
}

.formVersions li:hover{
    text-decoration: underline;
    color:#833c60;
    cursor: pointer;
}

.formListItemTitleAndButtonDiv{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.formListItemTitleAndButtonDivTitleInfo{
    width: 100%;
    padding-right: 10px;
}

.formListItemTitleAndButtonDivTitleInfo h3{
    margin-bottom: 5px;
}

.formListItemTitleAndButtonDivTitleInfo hr {
    margin: 0px;
    margin-bottom: 5px;
}

.formListItemViewButton{
    text-decoration: bold;
    background-color:#BD5196;
    width: 70px;
    color: #FFF;
    border-radius: 6px;
    border-color: transparent;
}

.formListItemViewButton:hover{
    cursor: pointer;
    background-color: #8DB147;
}

.formListItemDivToggleShowHideButton{
    padding: 5px;
    margin-top: 10px;
    width: 100%;
    background-color: #E1E1E1;
    border-radius: 6px;
    border-color: transparent;
    color: #BD5196;
    cursor: pointer;
}

.formListItemDivToggleShowHideButton:hover{
    cursor: pointer;
    
    background-color: #8DB147;
    color: white;
}

.formListItemDivToggleVersionsConfigTitle h6{
    font-weight: 600;
    font-size: 14px;
}

#webReferralConfigListDiv{
    width: 960px;
}

.ClientSettingEditorDiv{
    display: flex;
    flex-direction: column;
}

.ClientSettingEditorDiv label{
    font-size: 14px;
    width: 200px;
}

.ClientSettingEditorDiv label span{
    font-size: 16px;
    margin-bottom: 20px;
}

.ClientSettingEditorDiv input{
    width: 400px;
    padding: 4px;
    border-radius: 6px;
    border-color: #a1a1a1;
}

.ClientSettingEditorDiv select{
    width: 400px;
    padding: 4px;
    border-radius: 6px;
    border-color: #a1a1a1;
}

.clientSettingsListTableDiv{
    margin: 20px 0px 0px 0px;
}

.clientSettingsListTableHead{
    font-size: 14px;
    font-weight: 500;
    background-color:#E1E1E1;
    color:#2e2e2e;
    padding: 5px;
    border-bottom: 1px solid #a1a1a1;
}

.clientSettingListTableBody{
    padding: 5px;
}

.clientSettingsListTable{
    border: 1px solid;
    border-collapse: collapse;
}

.clientSettingListTableBody > input {
    padding: 5px;
    border: 1px solid #f1f1f1;
}

.clientSettingListTableBodyRows{
    transition: background-color 0.02s ease;
    background-color: #f1f1f1;
}

.clientSettingListTableBodyRows:hover {
    background-color: #8DB147;
    color: white;
}

.clientSettingListTableButton {
    margin-right: 4px;
    background-color: #DE7AAD;
    border: 1px solid #f1f1f1;
    color: #f1f1f1;
    padding: 8px;
    border-radius: 3px;
}

.clientSettingListTableButton:hover {
    background-color: #f1f1f1;
    color: #DE7AAD;
    cursor: pointer;
}
.clientSettingListTableButton:disabled{
    opacity: 50%;
}

.clientSettingListTableButton:disabled:hover{
    background-color: #DE7AAD;
    color: #f1f1f1;
    border: 1px solid #f1f1f1;
    cursor: default;
}

.clientSettingSearchButton{
    padding: 10px;
}

.clientSettingListTableBody.DefaultColumn{
    text-align: center;
}

